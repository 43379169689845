import React, { useEffect, useRef } from "react";
import {
  StyledPartners,
  StyledPartnersCarousel,
  StyledPartnersCarouselControl,
  StyledPartnersCarouselItem,
} from "./style";
import Swiper, { Navigation } from "swiper";
import prevArrowSrc from "../../../static/2020/images/impactcall/prev.svg";
import nextArrowSrc from "../../../static/2020/images/impactcall/next.svg";
import partner3Src from "../../../static/2020/images/impactcall/vincenzi.png";
import partner0Src from "../../../static/2020/images/impactcall/illy.jpg";

Swiper.use([Navigation]);

export const Partners = () => {
  const sliderEl = useRef(null);
  const prevArrowEl = useRef(null);
  const nextArrowEl = useRef(null);
  const partners = [
    partner0Src,
    partner3Src,
  ];
  useEffect(() => {
    new Swiper(sliderEl.current, {
      allowTouchMove: "ontouchstart" in window,
      observer: true,
      observeParents: true,
      autoHeight: true,
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      navigation: {
        prevEl: prevArrowEl.current,
        nextEl: nextArrowEl.current,
      },
      breakpoints: {
        768: {
          slidesPerView: "auto",
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: true,
        },
        1280: {
          slidesPerView: 4,
          centeredSlides: false,
        },
      },
    });
  }, []);

  return (
    <StyledPartners>
      <h2>I nostri partner</h2>
      <div>
        <StyledPartnersCarousel ref={sliderEl} className="swiper-container">
          <div className="swiper-wrapper">
            {partners.map((item, index) => (
              <StyledPartnersCarouselItem key={index} className="swiper-slide">
                <img src={item} loading="lazy" />
              </StyledPartnersCarouselItem>
            ))}
          </div>
        </StyledPartnersCarousel>
        <StyledPartnersCarouselControl ref={prevArrowEl}>
          <img src={prevArrowSrc} loading="lazy" />
        </StyledPartnersCarouselControl>
        <StyledPartnersCarouselControl ref={nextArrowEl}>
          <img src={nextArrowSrc} loading="lazy" />
        </StyledPartnersCarouselControl>
      </div>
    </StyledPartners>
  );
};
